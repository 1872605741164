import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import Liters from '../Liters';
import LoaderText from '../LoaderText';
import { withAuthorization } from '../Session';
import Sponsors from '../Sponsors';


class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cities: null,
      liters: null
    }
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    var cities = []
    var liters = 0
    for (var doc of (await this.props.firebase.cities().orderBy("name").get()).docs) {
      var data = doc.data()
      var image = ""
      if (data.image && data.image !== '')
        image = await this.props.firebase.fileUrl(data.image)
      cities.push({
        id: doc.id,
        name: data.name,
        image: image,
        liters: data.liters
      })
      liters += data.liters
    }
    this.setState({ cities, liters })
  }
  render() {
    return <div>
      <br />
      <h1>Scegli un comune</h1>

      <div className="row mt-4">
        {
          this.state.cities ? this.state.cities.map((city) => {
            var image = city.image || process.env.PUBLIC_URL + '/placeholder.jpg'
            return (
              <div key={city.id} className="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 p-2">
                <Link to={"/app/comuni/" + city.id} className="hvr-shrink nounderline">
                  <div className="card">
                    <div className="custom-card-image" style={{
                      backgroundImage: "url(" + image + ")",
                    }} alt="Immagine comune"></div>
                    <div className="card-body">
                      <h5 className="card-title">{city.name}</h5>
                      <p className="card-text">Litri totali {city.liters}</p>
                    </div>
                  </div>
                </Link>
              </div>
            )
          }
          ) : <LoaderText text="Caricando i comuni" />
        }

      </div>
      <br />
      {this.state.liters ? <div>
        <h3>Tabella dati intero progetto</h3>
        <Liters name="Tutti i comuni" liters={this.state.liters} />
      </div> : ""}
      <Sponsors/>
      <br />
    </div>
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(HomePage);
