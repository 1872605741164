import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import withAuthorization from '../../Session/withAuthorization'

import Template from './template_signup'

const INITIAL_STATE = {
  cities: [],
  schools: [],
  name: '',
  surname: '',
  cityId: 'viewer',
  schoolId: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  Esiste già un account con questa email. Accedi dalla pagina del login
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidUpdate() {
    if (this.props.user !== null && this.props.user !== undefined) {
      this.props.history.push(ROUTES.HOME);
    }
  }

  onSubmit = event => {
    const { name, surname, cityId, schoolId, email, passwordOne } = this.state;
    const roles = {};

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        var lastLogin = new Date()
        // Create a user in your Firebase firestore database
        return this.props.firebase.user(authUser.user.uid).set({
          name,
          surname,
          cityId,
          schoolId,
          email,
          roles,
          lastLogin
        });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onChangeCity = event => {
    var newCityId = event.target.value
    this.setState({ 'cityId': newCityId });
    if (newCityId !== 'viewer') {
      this.props.firebase.schools().where("city_id", "==", newCityId).get().then((result) => {
        var schools = result.docs.map((school) => {
          return {
            id: school.id,
            name: school.data().name
          }
        })
        this.setState({ schools: schools, schoolId: '' })
      })
    } else {
      this.setState({ schools: [], schoolId: '' })
    }
  };
  onChangeSchool = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  componentDidMount() {
    this.props.firebase.cities().get().then((result) => {
      var cities = result.docs.map((city) => {
        return {
          id: city.id,
          name: city.data().name
        }
      })
      this.setState({ cities: cities })
    })
  }
  render() {
    const isInvalid =
      this.state.passwordOne !== this.state.passwordTwo ||
      this.state.passwordOne === '' ||
      this.state.email === '';
    return (
      <Template title="Registrazione">
        <form onSubmit={this.onSubmit}>
          <div className="form-label-group">
            <input type="text" className="form-control" id="inputName" placeholder="Nome"
              name="name" value={this.state.name} onChange={this.onChange} required autoFocus />
            <label htmlFor="inputName">Nome</label>
          </div>
          <div className="form-label-group">
            <input type="text" id="inputSurname" className="form-control" placeholder="Cognome"
              name="surname" value={this.state.surname} onChange={this.onChange} required />
            <label htmlFor="inputSurname">Cognome</label>
          </div>
          <div className="form-label-group">
            <input type="text" id="inputEmail" className="form-control" placeholder="Email"
              name="email" value={this.state.email} onChange={this.onChange} required />
            <label htmlFor="inputEmail">Email</label>
          </div>
          <h5>Scegli comune</h5>
          <div className="form-label-group">
            <select onChange={this.onChangeCity} name="cityId" value={this.state.cityId} className="form-control">
              <option value="viewer">SONO UN UTENTE VISUALIZZATORE</option>
              {this.state.cities.map((city) => <option key={city.id} value={city.id}>{city.name}</option>)}
            </select>
          </div>
          <h5>Scegli scuola</h5>
          <div className="form-label-group">
            <select onChange={this.onChangeSchool} name="schoolId" value={this.state.schoolId} className="form-control" disabled={this.state.cityId === 'viewer' ? true : null}>
              <option value="">Scegli scuola</option>
              {this.state.schools.map((school) => <option key={school.id} value={school.id}>{school.name}</option>)}
            </select>
          </div>
          <div className="form-label-group">
            <input type="password" name="passwordOne" value={this.state.passwordOne} onChange={this.onChange}
              id="inputPasswordOne" className="form-control" placeholder="Password" required />
            <label htmlFor="inputPasswordOne">Password</label>
          </div>
          <div className="form-label-group">
            <input type="password" name="passwordTwo" value={this.state.passwordTwo} onChange={this.onChange}
              id="inputPasswordTwo" className="form-control" placeholder="Password" required />
            <label htmlFor="inputPasswordTwo">Conferma password</label>
          </div>
          <button disabled={isInvalid} type="submit" className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2">Registrati</button>

          {this.state.error && <p>{this.state.error.message}</p>}
        </form>
        Gestiremo i dati sul tuo conto come descritto nella nostra <a href="https://www.iubenda.com/privacy-policy/39112107" class="iubenda-nostyle iubenda-embed" title="Privacy Policy " target="_blank"  rel="noopener noreferrer">Normativa sulla privacy</a> e <a href="https://www.iubenda.com/privacy-policy/39112107/cookie-policy" class="iubenda-nostyle iubenda-embed" title="Cookie Policy " target="_blank"  rel="noopener noreferrer">Policy sui cookie</a>.
      </Template>
    );
  }
}

const condition = authUser => true;
const SignUpForm = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(SignUpFormBase);

export default SignUpForm;

