import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import PhoneVerificationPage from '../Authentication/SignUp/PhoneVerificationPage';

const needsPhoneVerification = authUser =>
    authUser &&
    !authUser.phoneNumber

const withPhoneVerification = Component => {
    class WithPhoneVerification extends React.Component {
        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        needsPhoneVerification(authUser) ? (<PhoneVerificationPage />) : (<Component {...this.props} />)
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return withFirebase(WithPhoneVerification);
};

export default withPhoneVerification;
