import React from 'react';
import { Container, Row, Spinner } from "react-bootstrap";

const Loader = () => {
    return <Container className="min-vh-100">
        <Row className="min-vh-100 justify-content-center align-items-center">
            <Spinner animation="grow" />
        </Row>
    </Container>
}
export default Loader