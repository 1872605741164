import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';

import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import Cities from './Cities';
import City from './City';
import Users from './Users';
import School from './School';
import Sponsor from './Sponsor';
import Plant from './Plant';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { withFirebase } from '../Firebase';
import Edit from './Edit';

const AdminPage = (props) => (
  <div>
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href={ROUTES.ADMIN}>Scuoliamola <small>Admin</small></Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href={ROUTES.ADMIN}>Comuni</Nav.Link>
          <Nav.Link href={ROUTES.ADMIN_USERS}>Utenti</Nav.Link>
          <Nav.Link href={ROUTES.ADMIN_EDIT}>Edit</Nav.Link>
        </Nav>
        <Button variant="outline-info" onClick={props.firebase.doSignOut}>Esci</Button>
      </Container>
    </Navbar>
    <Switch>
      <Route exact path={ROUTES.ADMIN_CITY_DETAIL} component={City} />
      <Route exact path={ROUTES.ADMIN} component={Cities} />
      <Route exact path={ROUTES.ADMIN_SCHOOL_DETAIL} component={School} />
      <Route exact path={ROUTES.ADMIN_PLANT_DETAIL} component={Plant} />
      <Route exact path={ROUTES.ADMIN_USERS} component={Users} />
      <Route exact path={ROUTES.ADMIN_EDIT} component={Edit} />
      <Route exact path={ROUTES.ADMIN_SPONSOR} component={Sponsor} />
    </Switch>
  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(AdminPage);
