import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import Liters from '../Liters';
import LoaderText from '../LoaderText';
import { withAuthorization, withEmailVerification } from '../Session';
import withPhoneVerification from '../Session/withPhoneVerification';
import Sponsors from '../Sponsors';

class SchoolPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            plants: null,
            school: null,
            city: null,
            liters: 0
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.load()
    }

    async load() {
        this.loadPlants()
        this.loadSchoolAndCity()
    }

    async loadPlants() {
        var query = await this.props.firebase.plants().where("school_id", "==", this.props.match.params.id).get()
        var plants = []
        for (var doc of query.docs) {
            var data = doc.data()
            plants.push({
                id: doc.id,
                name: data.name,
                imageLookup: data.image,
                image: null,
                liters: data.liters
            })
        }
        this.setState({ plants: plants })
        await this.loadImages();
    }

    async loadImages() {
        for (var plant of this.state.plants) {
            var image = ""
            if (plant.imageLookup && plant.imageLookup !== '')
                image = await this.props.firebase.fileUrl(plant.imageLookup)
            plant.image = image
            this.setState({ plants: this.state.plants })
        }
    }
    async loadSchoolAndCity() {
        var schoolDoc = await this.props.firebase.school(this.props.match.params.id).get()
        var data = schoolDoc.data()
        var image = await this.props.firebase.fileUrl(data.image)
        this.setState({
            school: {
                name: data.name,
                image: image,
                liters: data.liters
            }
        })
        var cityDoc = await this.props.firebase.city(data.city_id).get()
        this.setState({
            city: {
                id: cityDoc.id,
                name: cityDoc.data().name
            }
        })
    }

    render() {
        var image
        if (this.state.school) {
            image = this.state.school.image || process.env.PUBLIC_URL + '/placeholder.jpg'
        }
        return this.state.school && this.state.plants ? <div>
            <div className="mt-3">
                <Link to={ROUTES.HOME}>
                    Tutti i comuni
                </Link> /
                {this.state.city ?
                    <span> <Link to={"/app/comuni/" + this.state.city.id} >
                        {this.state.city.name}
                    </Link> / </span> : ''}
            </div>
            <div className="row">
                <div className="col-12 col-md-8 mt-3">
                    <h2>{this.state.school.name}</h2>
                    <div className="row">

                        {this.state.plants.map((plant) => {
                            var image = plant.image || process.env.PUBLIC_URL + '/placeholder.jpg'
                            return (
                                <div key={plant.id} className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 p-2">
                                    <Link to={"/app/impianti/" + plant.id} className="hvr-shrink nounderline">
                                        <div className="card">
                                            <div className="custom-card-image" style={{
                                                backgroundImage: "url(" + image + ")",
                                            }} alt="Immagine scuola"></div>
                                            <div className="card-body">
                                                <h5 className="card-title">{plant.name}</h5>
                                                <p className="card-text">Litri totali {plant.liters}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                    <br />
                    <h3>Tabella dati {this.state.school.name}</h3>
                    <Liters name={this.state.school.name} liters={this.state.school.liters} />

                </div>
                <div className="col-12 col-md-4 mt-3">
                    <img src={image} width="100%" alt="Immagine scuola" className="round-image" />
                    <Sponsors schoolId={this.props.match.params.id} column="true"></Sponsors>
                </div>
            </div>
        </div> : <LoaderText text="Caricando scuola" />
    }
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withPhoneVerification,
    withAuthorization(condition),
)(SchoolPage);
