import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withFirebase } from '../../Firebase';
import Uploader from '../Uploader'

const Sponsor = (props) => {
    const [loading, setLoading] = useState(true)
    const [sponsorName, setSponsorName] = useState('')
    const [sponsorLink, setSponsorLink] = useState('')
    const [sponsorImage, setSponsorImage] = useState('')
    const [updateSponsorEnabled, setUpdateSponsorEnabled] = useState(true)

    useEffect(() => {
        setLoading(true)
        props.firebase
            .sponsor(props.match.params.id).get().then((result) => {
                var data = result.data()
                setLoading(false)
                setSponsorName(data.name)
                setSponsorLink(data.link)
                setSponsorImage(data.image)
            })
    }, [props])

    function updateSponsorData() {
        setUpdateSponsorEnabled(false)
        props.firebase.sponsor(props.match.params.id).update({
            'name': sponsorName,
            'link': sponsorLink
        }).then(() => {
            setUpdateSponsorEnabled(true)
            toast.success("Dati aggiornati con successo", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    function openFile(fileName) {
        var ref = props.firebase.storageRef(fileName);
        ref.getDownloadURL().then(function (url) {
            window.open(url, "_blank")
        })
    }

    async function onImageUploaded(name, realName) {
        await props.firebase.sponsor(props.match.params.id).update({
            'image': name
        })
        setSponsorImage(name)
    }

    async function removeSponsorImage() {
        await props.firebase.sponsor(props.match.params.id).update({
            'image': ''
        })
        await props.firebase.deleteFile(sponsorImage)
        setSponsorImage(null)
    }

    return loading ? <Loader /> : (
        <div className="container">
            <div className="card mt-3">
                <div className="card-body">
                    <h3>Sponsor</h3>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-default">Nome sponsor</span>
                        </div>
                        <input type="text" className="form-control" value={sponsorName} onChange={(e) => setSponsorName(e.target.value)}
                            aria-label="Small" aria-describedby="inputGroup-sizing-default" name="sponsorName" />
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-default">Link</span>
                        </div>
                        <input type="text" className="form-control" value={sponsorLink} onChange={(e) => setSponsorLink(e.target.value)}
                            aria-label="Small" aria-describedby="inputGroup-sizing-default" name="sponsorLink" />
                    </div>
                    <button onClick={updateSponsorData} className="btn btn-primary" disabled={!updateSponsorEnabled}>Salva</button>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <h5>Immagine</h5>
                    {
                        sponsorImage !== null && sponsorImage !== '' ?
                            <div>Immagine caricata <button className="btn btn-primary mr-2" onClick={() => openFile(sponsorImage)}>Visualizza</button> <button className="btn btn-danger" onClick={removeSponsorImage}>Cancella</button></div>
                            :
                            <Uploader onFileUploaded={onImageUploaded} />
                    }
                </div>
            </div>
        </div>
    );
}

export default withFirebase(Sponsor);
