import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import './style.css'

const SignInOut = () => (
    <div className="d-flex justify-content-center">
        <Link to={ROUTES.SIGN_UP} className="btn btn-secondary btn-lg" style={{ marginRight: 5 }}>Registrati</Link>
        <Link to={ROUTES.SIGN_IN} className="btn btn-primary btn-lg" style={{ marginLeft: 5 }}>Accedi</Link>
    </div>
);

export default SignInOut;
