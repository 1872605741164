import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { compose } from 'recompose';

import withAuthorization from '../../Session/withAuthorization'
import { withFirebase } from '../../Firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './template_signin.css'
import Slideshow from '../../Slideshow';

const WRONG_PASS = "auth/wrong-password"

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInPageBase extends Component {
  constructor(props) {
    super();

    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {
    document.body.className = 'bg-gradient-custom'
  }
  componentWillUnmount() {
    document.body.className = ''
  }
  componentDidUpdate() {
    if (this.props.user !== null && this.props.user !== undefined) {
      this.props.history.push(ROUTES.HOME);
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .catch(error => {
        var message = "Errore durante il login, riprovare più tardi"
        if (error.code === WRONG_PASS) message = "Email o password errati"
        toast.error(message, {
          position: toast.POSITION.BOTTOM_CENTER
        });
        this.setState({ error });
      });

  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password } = this.state;

    const isInvalid = password === '' || email === '';
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="card card-signin my-5">
              <div className="card-body">
                <h5 className="card-title text-center">Sign In</h5>
                <form className="form-signin" onSubmit={this.onSubmit}>
                  <div className="form-label-group">
                    <input name="email" value={email} onChange={this.onChange}
                      placeholder="Email" type="email" id="inputEmail" className="form-control" required autoFocus />
                    <label htmlFor="inputEmail">Email address</label>
                  </div>

                  <div className="form-label-group">
                    <input type="password" name="password" value={password} onChange={this.onChange}
                      id="inputPassword" className="form-control" placeholder="Password" required />
                    <label htmlFor="inputPassword">Password</label>
                  </div>

                  <button disabled={isInvalid} className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Login</button>
                  <div className="text-center mt-2">
                    <Link to={ROUTES.PASSWORD_FORGET} className="small">Dimenticato la Password?</Link>
                  </div>
                  <hr className="my-4" />
                  <Link to={ROUTES.SIGN_UP} className="btn btn-lg btn-link btn-block text-uppercase">Crea il tuo Account</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-3"><Link className="btn btn-light" to={ROUTES.LANDING}>Torna alla home</Link></div>

        <div className="row justify-content-md-center">

          <div className="col-md-5 mb-4">
            <Slideshow />
          </div>

        </div>
      </div>
    )
  }
}

const condition = authUser => true;
const SignInPage = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition)
)(SignInPageBase);

export default SignInPage;
