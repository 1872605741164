import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withFirebase } from '../../Firebase';
import Uploader from '../Uploader';
import Sponsors from '../Sponsors';

const City = (props) => {
    const [loading, setLoading] = useState(true)
    const [schools, setSchools] = useState([])
    const [cityName, setCityName] = useState("")
    const [cityImage, setCityImage] = useState("")
    const [updateDataEnabled, setUpdateDataEnabled] = useState(true)

    useEffect(() => {
        setLoading(true)
        props.firebase.city(props.match.params.id).get().then((doc) => {
            var data = doc.data()
            setCityName(data.name)
            setCityImage(data.image)
        })
        var unsubscribe = props.firebase
            .schools().where("city_id", "==", props.match.params.id).onSnapshot((result) => {
                var schoolsRes = result.docs.map((school) => {
                    return {
                        id: school.id,
                        name: school.data().name,
                        liters: school.data().liters
                    }
                })
                setLoading(false)
                setSchools(schoolsRes)
            })
        return () => {
            unsubscribe()
        }
    }, [props])

    function onChange(event) {
        setCityName(event.target.value);
    }
    function updateData() {
        setUpdateDataEnabled(false)
        props.firebase.city(props.match.params.id).update({
            'name': cityName
        }).then(() => {
            toast.success("Dati aggiornati con successo", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setUpdateDataEnabled(true)
        })
    }

    function newSchool() {
        var elem = document.getElementById("nuovaScuola")
        props.firebase.schools().add({
            name: elem.value,
            city_id: props.match.params.id,
            image: '',
            liters: 0
        })
    }

    function onDelete(schoolId) {
        props.firebase.deleteSchool(schoolId)
    }

    function openFile(fileName) {
        var ref = props.firebase.storageRef(fileName);
        ref.getDownloadURL().then(function (url) {
            window.open(url, "_blank")
        })
    }

    async function onImageUploaded(name, realName) {
        await props.firebase.city(props.match.params.id).update({
            'image': name
        })
        setCityImage(name)
    }

    async function removeCityImage() {
        await props.firebase.city(props.match.params.id).update({
            'image': ''
        })
        await props.firebase.deleteFile(cityImage)
        setCityImage(null)
    }


    return loading ? <Loader /> : (
        <div className="container">
            <div className="mt-3">
                <Link to={"/admin"}> <button className="btn btn-link">Indietro</button></Link>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <h3>Comune</h3>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-default">Nome comune</span>
                        </div>
                        <input type="text" className="form-control" value={cityName} onChange={onChange} aria-label="Small" aria-describedby="inputGroup-sizing-default" name="cityName" />
                    </div>
                    <button onClick={updateData} className="btn btn-primary" disabled={!updateDataEnabled}>Salva</button>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <h5>Immagine</h5>
                    {
                        cityImage !== null && cityImage !== '' ?
                            <div>Immagine caricata <button className="btn btn-primary mr-2" onClick={() => openFile(cityImage)}>Visualizza</button> <button className="btn btn-danger" onClick={removeCityImage}>Cancella</button></div>
                            :
                            <Uploader onFileUploaded={onImageUploaded} />
                    }
                </div>
            </div>
            <div className="my-3" style={{ "width": "350px" }}>
                <div className="input-group">
                    <input type="text" id="nuovaScuola" className="form-control" />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={newSchool}>Nuova scuola</button>
                    </div>
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Scuola</th>
                        <th scope="col">Liters</th>
                        <th scope="col" style={{ width: '250px' }}>Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {schools.map((school) => (
                        <tr key={school.id}>
                            <td>{school.name}</td>
                            <td>{school.liters}</td>
                            <td><Link to={"/admin/scuole/" + school.id}><button className="btn btn-primary mr-2">Modifica</button></Link>
                                <button className="btn btn-danger" onClick={() => { if (window.confirm('Sei sicuro di voler eliminare questa scuola?')) onDelete(school.id) }}>Elimina</button></td>
                        </tr>))}
                </tbody>
            </table>

            <Sponsors cityId={props.match.params.id}></Sponsors>
        </div>
    );
}


export default withFirebase(City);
