import React from 'react';
import { compose } from 'recompose';
import { useFirebase } from '../Firebase/context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withAuthorization } from '../Session';

const AccountPage = (props) => {
  const firebase = useFirebase()

  function resetPassword() {
    firebase.doPasswordReset(props.user.email).then(() => {
      toast.success("Email inviata correttamente", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }).catch((e) => {
      toast.error("Errore durante l'invio. Ricontrolla i dati", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    })
  }

  function deleteAccount() {
    firebase.deleteUser()
  }

  return <div className="container container-sm mt-4">
    <h2>I tuoi dati</h2>
    <table width="100%">
      <tbody>
        <tr>
          <td>Email</td>
          <th>{props.user.email}</th>
        </tr>
        <tr>
          <td>Nome cognome</td>
          <th>{props.user.name} {props.user.surname}</th>
        </tr>
        <tr>
          <td>Telefono</td>
          <th>{props.user.phoneNumber}</th>
        </tr>
      </tbody>
    </table>
    <hr />
    <h3>Resetta la password</h3>
    <p>Per resettare la password del tuo account clicca su questo link e segui le istruzioni che riceverai per email.</p>
    <button className="btn btn-primary clearfix" onClick={resetPassword}>Resetta la password</button>
    <hr />
    <h3>Elimina account</h3>
    <p>Per eliminare il tuo account clicca sul pulsante qui sotto.</p>
    <button className="btn btn-danger clearfix" onClick={() => { if (window.confirm('Sei sicuro di voler eliminare il tuo account?')) deleteAccount() }}>Elimina il tuo account</button>
  </div>
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
)(AccountPage);
