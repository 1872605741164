import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import LandingPage from '../Landing';
import SignUpPage from '../Authentication/SignUp';
import SignInPage from '../Authentication/SignIn';
import PasswordForgetPage from '../Authentication/PasswordForget';
import AdminPage from '../Admin';
import Navigation from '../Navigation'

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { toast } from 'react-toastify';

import '../../fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import PhoneVerificationPage from '../Authentication/SignUp/PhoneVerificationPage';

toast.configure()

const App = () => (
  <Router>
    <Route exact path={ROUTES.LANDING} component={LandingPage} />
    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route path={ROUTES.SIGN_UP_PHONE} component={PhoneVerificationPage} />
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
    <Route path={ROUTES.APP} component={Navigation} />
    <Route path={ROUTES.ADMIN} component={AdminPage} />
  </Router>
);

export default withAuthentication(App);
