import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyCVIEFhLy9XTT_PzH2DMFXxHFpZXjsoTBc",
  authDomain: "scuoliamola.firebaseapp.com",
  databaseURL: "https://scuoliamola.firebaseio.com",
  projectId: "scuoliamola",
  storageBucket: 'scuoliamola.appspot.com',
  messagingSenderId: "814392382571",
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    app.auth().languageCode = 'it';

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = async function (email, password) {
    var credentials = await this.auth.signInWithEmailAndPassword(email, password)
    await this.user(credentials.user.uid).update({ lastLogin: new Date() })
  }

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "https://scuoliamola.it/app/home",
    });

  getRecaptcha = (id, callbackSuccess, callbackExpired) => new app.auth.RecaptchaVerifier(id, {
    'size': 'normal',
    'callback': function (response) {
      callbackSuccess(response)
    },
    'expired-callback': function () {
      callbackExpired()
    }
  });

  doSendPhoneVerification = (phoneNumber, appVerifier) =>
    this.auth.signInWithPhoneNumber(phoneNumber, appVerifier)

  confirmPhoneCode = async (confirmationResult, code, onError) => {
    try {
      const credential = app.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, code);
      await this.auth.currentUser.updatePhoneNumber(credential)
      this.onNewUser(this.auth.currentUser, this.next, this.fallback)
    }
    catch (e) {
      console.log(e)
      onError(e)
    }
  }

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => this.onNewUser(authUser, next, fallback));

  onNewUser = (authUser, next, fallback) => {
    this.fallback = fallback;
    this.next = next;
    if (authUser) {
      this.user(authUser.uid).get()
        .then(snapshot => {
          if (!snapshot.exists) return next(null)
          const dbUser = snapshot.data();
          // default empty roles
          if (!dbUser.roles) {
            dbUser.roles = {};
          }

          // merge auth and db user
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            phoneNumber: authUser.phoneNumber,
            providerData: authUser.providerData,
            ...dbUser,
          };

          next(authUser);
        });
    } else {
      fallback();
    }
  }

  deleteUser = async function () {
    await this.user(this.auth.currentUser.uid).update({ requestDelete: true })
    await this.auth.currentUser.delete()
    await this.doSignOut()
  }

  uploadFile = (file, name) => this.storage.ref().child(name).put(file)

  storageRef = (path) => this.storage.ref(path)

  // *** User API ***

  user = uid => this.db.collection('users').doc(uid);
  users = () => this.db.collection('users');

  city = uid => this.db.collection('cities').doc(uid);
  cities = () => this.db.collection('cities');

  school = uid => this.db.collection('schools').doc(uid);
  schools = () => this.db.collection('schools');

  plant = uid => this.db.collection('plants').doc(uid);
  plants = () => this.db.collection('plants');

  file = uid => this.db.collection('files').doc(uid);
  files = () => this.db.collection('files');

  allSponsors = () => this.db.collection('sponsors');
  sponsor = uid => this.db.collection('sponsors').doc(uid);

  sponsors = function (cityId, schoolId, plantId) {
    if (cityId != null) return this.db.collection('sponsors').where("city_id", "==", cityId);
    if (schoolId != null) return this.db.collection('sponsors').where("school_id", "==", schoolId);
    if (plantId != null) return this.db.collection('sponsors').where("plant_id", "==", plantId);
    return this.db.collection('sponsors').where("cities", "==", true);
  }

  updatePlant = async (plantId, name, liters) => {
    await this.db.runTransaction(async function (transaction) {
      var plantRef = app.firestore().collection('plants').doc(plantId)
      var plantDoc = await transaction.get(plantRef)
      var diffLiters = liters - plantDoc.data().liters

      var schoolRef = app.firestore().collection('schools').doc(plantDoc.data().school_id)
      var schoolDoc = await transaction.get(schoolRef)
      var schoolLiters = schoolDoc.data().liters || 0

      var cityRef = app.firestore().collection('cities').doc(schoolDoc.data().city_id)
      var cityDoc = await transaction.get(cityRef)
      var cityLiters = cityDoc.data().liters || 0

      await transaction.update(cityRef, { liters: cityLiters + diffLiters });
      await transaction.update(schoolRef, { liters: schoolLiters + diffLiters });
      var updateObj = {}
      if (name)
        updateObj['name'] = name;
      if (liters)
        updateObj['liters'] = liters
      await transaction.update(plantRef, updateObj);
    })
  }

  deleteCity = async (cityId) => {
    console.log("delete city " + cityId)
    var city = await this.city(cityId).get()
    await this.deleteFile(city.data().image)
    var schools = await this.schools().where("city_id", "==", cityId).get()
    for (var school of schools.docs) {
      await this.deleteSchool(school.id)
    }
    await this.city(cityId).delete()
  }

  deleteSchool = async (schoolId) => {
    console.log("delete school " + schoolId)
    var school = await this.school(schoolId).get()
    await this.deleteFile(school.data().image)
    var plants = await this.plants().where("school_id", "==", schoolId).get()
    for (var plant of plants.docs) {
      await this.deletePlant(plant.id)
    }
    await this.school(schoolId).delete()
  }

  deletePlant = async (plantId) => {
    console.log("delete plant " + plantId)
    var plant = await this.plant(plantId).get()
    await this.updatePlant(plantId, plant.data().name, 0)
    var files = await this.files().where("plant_id", "==", plantId).get()
    for (var file of files.docs) {
      await this.deletePlantFile(file.id)
    }
    await this.plant(plantId).delete()
    await this.deleteFile(plant.data().image)
  }

  deletePlantFile = async (fileId) => {
    console.log("delete school file " + fileId)
    var file = await this.file(fileId).get()
    await this.file(fileId).delete()
    await this.deleteFile(file.data().name)
  }

  deleteFile = async (fileName) => {
    console.log("delete file " + fileName)
    if (fileName !== null && fileName !== "") {
      try {
        await this.storageRef(fileName).delete()
      } catch (e) {
        console.log("error deleting file")
      }
    }
  }

  deleteSponsor = async (sponsorId) => {
    console.log("delete sponsor " + sponsorId)
    var sponsor = await this.sponsor(sponsorId).get()
    await this.deleteFile(sponsor.data().image)
    await this.sponsor(sponsorId).delete()
  }

  updateUserPhone = function (phoneNumber) {
    console.log(phoneNumber)
    var currentUser = this.auth.currentUser
    return this.user(currentUser.uid).update({
      phone: phoneNumber
    })
  }

  fileUrl = async (path) => {
    if (!path || path === '') return ''
    return await this.storageRef(path).getDownloadURL()
  }
}

export default Firebase;
