import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../Firebase/context';
import { Link } from 'react-router-dom';

const Sponsors = (props) => {
    const [loading, setLoading] = useState(true)
    const [sponsors, setSponsors] = useState([])
    const firebase = useFirebase()

    useEffect(() => {
        var unsubscribe = firebase.sponsors(props.cityId, props.schoolId, props.plantId).onSnapshot((result) => {
            console.log("got sponsors")
            var sponsorsRes = []
            result.docs.forEach((sponsor) => {
                sponsorsRes.push({
                    id: sponsor.id,
                    name: sponsor.data().name,
                })
            });
            setLoading(false)
            setSponsors(sponsorsRes)
        })
        return () => {
            unsubscribe()
        }
    }, [firebase,props.cityId, props.schoolId, props.plantId]);

    function newSponsor() {
        var elem = document.getElementById("nuovoSponsor")
        setLoading(true)
        var params = { name: elem.value, image:'', link:'' }
        if (props.cityId) params.city_id = props.cityId
        else if (props.schoolId) params.school_id = props.schoolId
        else if (props.plantId) params.plant_id = props.plantId
        else params.cities = true
        firebase.allSponsors().add(params)
    }

    function onDelete(sposorId) {
        setLoading(true)
        firebase.deleteSponsor(sposorId)
    }

    return (!loading ? (
        <div >
            <h3 className="mt-3">Sponsor</h3>
            <div className="my-3" style={{ "width": "350px" }}>
                <div className="input-group">
                    <input type="text" id="nuovoSponsor" className="form-control" />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={newSponsor}>Nuovo sponsor</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Sponsor</th>
                            <th scope="col" style={{ width: '250px' }}>Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sponsors.map((sponsor) => {
                            return (
                                <tr key={sponsor.id}>
                                    <td>{sponsor.name}</td>
                                    <td><Link to={"/admin/sponsor/" + sponsor.id}><button className="btn btn-primary mr-2">Modifica</button></Link>
                                        <button className="btn btn-danger" onClick={() => { if (window.confirm('Sei sicuro di voler eliminare questo sponsor?')) onDelete(sponsor.id) }}>Elimina</button></td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>) : (<div>Loading sponsors</div>))
}

export default Sponsors;