import React, { useState } from 'react'
import Template from '../SignUp/template_signup'
import { useFirebase } from '../../Firebase/context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordForgetPage = () => {
    const firebase = useFirebase()
    const [email, setEmail] = useState('')

    function resetPassword() {
        firebase.doPasswordReset(email).then(() => {
            toast.success("Email inviata correttamente", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }).catch((e) => {
            toast.error("Errore durante l'invio. Ricontrolla i dati", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }
    return <Template title="Password dimenticata?">
        Inserisci l'indirizzo email che hai usato al momento della registrazione. <br />
        Riceverai una mail contente le istruzioni per cambiare la tua password all'indirizzo indicato.
        <div className="form-inline mt-3">
            <div className="form-group mr-2">
                <input type="text" placeholder="example@test.com" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <button className="btn btn-primary" onClick={resetPassword}>Reset password</button>
        </div>
    </Template>
}

export default PasswordForgetPage