import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './template_signup.css'
import * as ROUTES from '../../../constants/routes';

function Template(props) {
    useEffect(() => {
        document.body.className = 'bg-gradient-custom'
        return () => {
            document.body.className = ''
        }
    }, [])
    return (
        <div className="container" >
            <div className="row">
                <div className="col-lg-10 col-xl-9 mx-auto">
                    <div className="card card-signin flex-row my-5">
                        <div className="card-img-left d-none d-md-flex"></div>
                        <div className="card-body">
                            <h5 className="card-title text-center">{props.title}</h5>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mb-3"><Link className="btn btn-light" to={ROUTES.LANDING}>Torna alla home</Link></div>
        </div>
    );
}

export default Template;
