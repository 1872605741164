import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../Firebase/context';
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import Sponsors from '../Sponsors';

const Cities = (props) => {
    const [loading, setLoading] = useState(true)
    const [cities, setCities] = useState([])
    const firebase = useFirebase()

    useEffect(() => {
        console.log("cities loaded")
        var unsubscribe = firebase.cities().orderBy("name", "asc").onSnapshot((result) => {
            var citiesRes = []
            result.docs.forEach((city) => {
                citiesRes.push({
                    id: city.id,
                    name: city.data().name,
                    active: city.data().active,
                    liters: city.data().liters
                })
            });
            setLoading(false)
            setCities(citiesRes)
        })
        return () => {
            unsubscribe()
        }
    }, [firebase]);

    function newCity() {
        var elem = document.getElementById("nuovoComune")
        setLoading(true)
        firebase.cities().add({
            name: elem.value,
            image: '',
            liters: 0
        })
    }

    function onDelete(cityId) {
        setLoading(true)
        firebase.deleteCity(cityId)
    }

    return (!loading ? (
        <div className="container">
            <h3 className="mt-3">Comuni</h3>
            <div className="my-3" style={{ "width": "350px" }}>
                <div className="input-group">
                    <input type="text" id="nuovoComune" className="form-control" />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={newCity}>Nuovo comune</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Comune</th>
                            <th scope="col">Litri</th>
                            <th scope="col" style={{ width: '250px' }}>Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cities.map((city) => {
                            return (
                                <tr key={city.id}>
                                    <td>{city.name}</td>
                                    <td>{city.liters}</td>
                                    <td><Link to={"/admin/comuni/" + city.id}><button className="btn btn-primary mr-2">Modifica</button></Link>
                                        <button className="btn btn-danger" onClick={() => { if (window.confirm('Sei sicuro di voler eliminare questo comune?')) onDelete(city.id) }}>Elimina</button></td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <Sponsors></Sponsors>
        </div>) : (<Loader />))
}

export default Cities;