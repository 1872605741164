import React from 'react';

const Liters = (props) => {
    var pet, kgPet, idrocarburi, ossidiZolfo, co, co2, lNecessari
    pet = (props.liters / 1.5).toFixed(0)
    kgPet = (pet * 0.035).toFixed(1)
    idrocarburi = (kgPet * 0.04).toFixed(1)
    ossidiZolfo = (kgPet * 0.025).toFixed(1)
    co = (kgPet * 0.018).toFixed(1)
    co2 = (kgPet * 0.035).toFixed(1)
    lNecessari = (kgPet * 17.5).toFixed(0)

    return <div>
        <table className="table">
            <tbody>
                <tr>
                    <td colSpan="2" align="right" style={{ border: 'none' }}><span className="small"></span></td>
                </tr>
                <tr>
                    <th colSpan="2">RISPARMIO ENERGETICO</th>
                </tr>
                <tr>
                    <td>LITRI DI ACQUA CONSUMATA</td>
                    <td><strong>{props.liters}</strong></td>
                </tr>
                <tr>
                    <td>Numero equivalente di bottiglie di PET da 1,5 litri</td>
                    <td><strong>{pet}</strong></td>
                </tr>
                <tr>
                    <td>Quantità PET(bottiglie di plastica) “risparmiato”</td>
                    <td><strong>{kgPet}</strong> KG</td>
                </tr>
                <tr>
                    <th colSpan="2">La produzione di tale quantità di PET avrebbe rilasciato in atmosfera:</th>
                </tr>
                <tr>
                    <td>Idrocarburi</td>
                    <td><strong>{idrocarburi}</strong> KG</td>
                </tr>
                <tr>
                    <td>Ossidi di zolfo</td>
                    <td><strong>{ossidiZolfo}</strong> KG</td>
                </tr>
                <tr>
                    <td>Monossido di carbonio</td>
                    <td><strong>{co}</strong> KG</td>
                </tr>
                <tr>
                    <td>Anidride carbonica</td>
                    <td><strong>{co2}</strong> KG</td>
                </tr>
                <tr>
                    <td colSpan="2">Sarebbero inoltre stati indispensabili per il ciclo produttivo <strong>{lNecessari}</strong> litri d'acqua, necessari alla produzione delle bottiglie in PET</td>
                </tr>
                <tr>
                    <td colSpan="2"><span className="small">(Calcoli effettuati con dati forniti da “Paul Mc Rande, The green guide,in State of the world 2004 Edizioni Ambiente, Milano 2004, pagg. 136-137).</span></td>
                </tr>
            </tbody>
        </table>
    </div>
}

export default Liters