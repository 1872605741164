import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const fadeImages = [
    'photos/bottiglie.jpeg',
    'photos/classe1.jpg',
    'photos/rubinetto2.jpg'
];

const Slideshow = (props) => {
    return (
        <div className="slide-container">
            <Fade indicators={false} arrows={false}>
                {
                    fadeImages.map((image) => (
                        <div className="each-fade" key={image}>
                            <div className="image-container">
                                <div style={{
                                    backgroundImage: `url(${image})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: 10,
                                    height: props.height || 200,
                                }} />
                            </div>
                        </div>)
                    )
                }
            </Fade>
        </div>
    )
}

export default Slideshow