import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withFirebase } from '../../Firebase';

class UploaderBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            uploading: false
        }
    }

    onSubmit = async event => {
        event.preventDefault();
        this.setState({
            uploading: true
        })
        if (this.state.selectedFile !== null) {
            var splits = this.state.selectedFile.name.split(".")
            var ext = splits[splits.length - 1]
            var name = "file_" + Date.now() + "." + ext;
            var result = await this.props.firebase.uploadFile(this.state.selectedFile, name)
            if (result.state === 'success') {
                this.props.onFileUploaded(name, this.state.selectedFile.name)
                this.setState({
                    uploading: false
                })
                toast.success("File caricato con successo", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }
    };

    onChange = event => {
        this.setState({
            selectedFile: event.target.files[0],
        })
    };

    render() {
        return (
            <form onSubmit={this.onSubmit} className="mb-3">
                <div className="input-group">
                    <div className="custom-file">
                        <input type="file" onChange={this.onChange} id="inputFile" />
                    </div>
                    {!this.state.uploading ? <button className="btn btn-outline-secondary" type="submit">Carica</button> : ''}
                </div>
            </form>
        );
    }
}
export default withFirebase(UploaderBase);