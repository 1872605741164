import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import Liters from '../Liters';
import LoaderText from '../LoaderText';
import { withAuthorization } from '../Session';
import Sponsors from '../Sponsors';


class CityPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            schools: null,
            city: null
        }
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.props.firebase.schools().where("city_id", "==", this.props.match.params.id).get().then(async (res) => {
            var schools = []
            for (var doc of res.docs) {
                var data = doc.data()
                var image = ""
                if (data.image && data.image !== '')
                    image = await this.props.firebase.fileUrl(data.image)
                schools.push({
                    id: doc.id,
                    name: data.name,
                    image: image,
                    liters: data.liters
                })
            }
            this.setState({
                schools: schools
            })
        });
        this.props.firebase.city(this.props.match.params.id).get().then((doc) => {
            var data = doc.data()
            this.setState({
                city: {
                    name: data.name,
                    liters: data.liters
                }
            })
        })
    }
    render() {
        return this.state.schools && this.state.city ? <div>
            <div className="mt-3">
                <Link to={ROUTES.HOME}>
                    Tutti i comuni
                </Link> /
            </div>
            <br />
            {this.state.city !== null ? <h1>{this.state.city.name}</h1> : <span></span>}
            <div className="row mt-4">
                {this.state.schools.map((school) => {
                    var image = school.image || process.env.PUBLIC_URL + '/placeholder.jpg'
                    return (
                        <div key={school.id} className="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 p-2">
                            <Link to={"/app/scuole/" + school.id} className="hvr-shrink nounderline">
                                <div className="card">
                                    <div className="custom-card-image" style={{
                                        backgroundImage: "url(" + image + ")",
                                    }} alt="Immagine scuola"></div>
                                    <div className="card-body">
                                        <h5 className="card-title">{school.name}</h5>
                                        <p className="card-text">Litri totali {school.liters}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </div>
            <br />
            {this.state.city !== null ? <div><h3>Tabella dati per il comune di {this.state.city.name}</h3><Liters name={this.state.city.name} liters={this.state.city.liters} /></div> : ''}
            <Sponsors cityId={this.props.match.params.id} />
            <br />
        </div>
            : <LoaderText text="Caricando comune" />
    }
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
)(CityPage);
