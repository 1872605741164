import React from 'react';
import { Container, Row, Spinner } from "react-bootstrap";

const LoaderText = (props) => {
    return <Container style={{ marginTop: 100 }}>
        <Row className="justify-content-center align-items-center">
            <Spinner animation="grow" />
            <div className="ml-3"></div>
            {props.text || "Caricando"}
        </Row>
    </Container>
}
export default LoaderText