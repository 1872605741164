import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import './style.css'
import SignInOut from './sign_in_out'

const Landing = () => (
  <div>
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark fixed-top" id="mainNav">
      <ul className="navbar-nav ml-auto" >
        <li className="nav-item">
          <Link to={ROUTES.SIGN_IN} className="nav-link">Accedi</Link>
        </li>
        <li className="nav-item">
          <Link to={ROUTES.SIGN_UP} className="nav-link">Registrati</Link>
        </li>
      </ul>
    </nav>

    <header className="text-white bg-gradient-custom">
      <div className="container text-center">
        <h1>Scuoliamola</h1>
        <p className="lead">La cultura del <strong>#plasticfree</strong> nelle scuole primarie e secondarie</p>
      </div>
    </header>

    <div className="mt-5"><SignInOut /></div>

    <section id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2>Il progetto</h2>
            <div className="col-sm-4 home-image home-image-right" style={{ backgroundImage: "url(/photos/plastic_free.jpeg)" }}></div>
            <p className="lead">Nato da un’idea di Chiara Pelassa, presidente della onlus “API di CARTA” in collaborazione con la PIERH2O (Ambassador del progetto), azienda Piemontese che da oltre 10 anni è specializzata nel trattamento dell’acqua ad uso alimentare, il progetto SCUOLIAMOLA vuole trasmettere la cultura del #plasticfree nelle scuole primarie e secondarie di primo livello del Piemonte e delle regioni limitrofe.</p>
            <div className="col-sm-4 home-image home-image-left" style={{ backgroundImage: "url(/photos/classe1.jpg)" }}></div>
            <p className="lead">Come in tutti i progetti ambiziosi, il primo passo è sempre quello più difficile da organizzare e da fare, va quindi ringraziato il comune di Poirino, e le relative scuole pubbliche e private, per aver dato la possibilità al progetto di decollare.</p>
          </div>
        </div>
      </div>
    </section>


    <section id="services" className="bg-light">
      <div className="container">

        <div className="mb-5"><SignInOut /></div>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2>COME FUNZIONA</h2>
            <div className="col-sm-4 home-image home-image-right" style={{ backgroundImage: "url(/photos/rubinetto1.jpeg)" }}></div>
            <p className="lead">Il progetto SCUOLIAMOLA prevede che ad ogni bambino di ogni scuola che aderisce al progetto, venga fornita una borraccia in alluminio da 0,5 l. ma soprattutto prevede che nella scuola vengano installati un congruo numero di “depuratori d’acqua” dove i bambini possano riempire le borracce che sono state fornite.</p>
            <p className="lead">Dai genitori, ovviamente, una serie di domande sorgono spontanee, chi garantisce la qualità dell’acqua proveniente dai depuratori? Siamo sicuri che poi i filtri vengano effettivamente sostituiti quando necessario?</p>
            <div className="col-sm-4 home-image home-image-left" style={{ backgroundImage: "url(/photos/classe2.jpeg)" }}></div>
            <p className="lead">Per rispondere a queste domande, e per responsabilizzare tutta la filiera, nasce il portale SCUOLIAMOLA.IT, dove ogni genitore, oppure qualsiasi ente politico od amministrativo, può liberamente iscriversi, e verificare di persona in quali Comune ed in quali scuole sono installati gli “depuratori”, quali sono stati nel tempo i vari cambi filtro, ed ovviamente potranno consultare anche le relative analisi dell’acqua, eseguite da laboratorio accreditato.</p>
          </div>
        </div>
        <div className="mb-3 mt-5"><SignInOut /></div>

      </div>
    </section>

    <footer className="py-5 bg-dark">
      <div className="container">
        <p className="m-0 text-center text-white">PIER H20 Srl - Via Bisalta, 70 - 12100 Cuneo (CN) - R.I./C.F./P.IVA: 03318150046</p>
        <p className="m-0 text-center text-secondary">Copyright &copy; 2020</p>
      </div>
    </footer>
  </div>
);

export default Landing;
