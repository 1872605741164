import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withFirebase } from '../../Firebase';
import Uploader from '../Uploader'
import Sponsors from '../Sponsors';

const School = (props) => {
    const [loading, setLoading] = useState(true)
    const [plants, setPlants] = useState([])
    const [schoolName, setSchoolName] = useState('')
    const [schoolCityId, setSchoolCityId] = useState('')
    const [schoolImage, setSchoolImage] = useState('')
    const [updateSchoolEnabled, setUpdateSchoolEnabled] = useState(true)

    useEffect(() => {
        setLoading(true)
        props.firebase
            .school(props.match.params.id).get().then((result) => {
                var data = result.data()
                setLoading(false)
                setSchoolName(data.name)
                setSchoolCityId(data.city_id)
                setSchoolImage(data.image)
            })
        var unsubscribePlants = props.firebase
            .plants().where("school_id", "==", props.match.params.id).onSnapshot((result) => {
                var plantsRes = result.docs.map((plant) => {
                    return {
                        id: plant.id,
                        name: plant.data().name,
                        liters: plant.data().liters
                    }
                })
                setLoading(false)
                setPlants(plantsRes)
            })

        return () => {
            unsubscribePlants()
        }
    }, [props])

    function updateSchoolData() {
        setUpdateSchoolEnabled(false)
        props.firebase.school(props.match.params.id).update({
            'name': schoolName
        }).then(() => {
            setUpdateSchoolEnabled(true)
            toast.success("Dati aggiornati con successo", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    function openFile(fileName) {
        var ref = props.firebase.storageRef(fileName);
        ref.getDownloadURL().then(function (url) {
            window.open(url, "_blank")
        })
    }

    function newPlant() {
        var elem = document.getElementById("nuovoImpianto")
        props.firebase.plants().add({
            name: elem.value,
            school_id: props.match.params.id,
            image: '',
            liters: 0
        })
    }

    function onDelete(plantId) {
        props.firebase.deletePlant(plantId)
    }


    async function onImageUploaded(name, realName) {
        await props.firebase.school(props.match.params.id).update({
            'image': name
        })
        setSchoolImage(name)
    }

    async function removeSchoolImage() {
        await props.firebase.school(props.match.params.id).update({
            'image': ''
        })
        await props.firebase.deleteFile(schoolImage)
        setSchoolImage(null)
    }

    return loading ? <Loader /> : (
        <div className="container">
            <div className="mt-3">
                <Link to={"/admin/comuni/" + schoolCityId}> <button className="btn btn-link">Indietro</button></Link>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <h3>Scuola</h3>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-default">Nome scuola</span>
                        </div>
                        <input type="text" className="form-control" value={schoolName} onChange={(e) => setSchoolName(e.target.value)}
                            aria-label="Small" aria-describedby="inputGroup-sizing-default" name="schoolName" />
                    </div>
                    <button onClick={updateSchoolData} className="btn btn-primary" disabled={!updateSchoolEnabled}>Salva</button>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <h5>Immagine</h5>
                    {
                        schoolImage !== null && schoolImage !== '' ?
                            <div>Immagine caricata <button className="btn btn-primary mr-2" onClick={() => openFile(schoolImage)}>Visualizza</button> <button className="btn btn-danger" onClick={removeSchoolImage}>Cancella</button></div>
                            :
                            <Uploader onFileUploaded={onImageUploaded} />
                    }
                </div>
            </div>
            <br />
            <h3>Gestione impianti</h3>
            <div className="mt-1 mb-3" style={{ "width": "350px" }}>
                <div className="input-group">
                    <input type="text" id="nuovoImpianto" className="form-control" />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={newPlant}>Nuovo impianto</button>
                    </div>
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Impianto</th>
                        <th scope="col">Liters</th>
                        <th scope="col" style={{ width: '250px' }}>Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {plants.map((plant) => (
                        <tr key={plant.id}>
                            <td>{plant.name}</td>
                            <td>{plant.liters}</td>
                            <td><Link to={"/admin/impianti/" + plant.id}><button className="btn btn-primary mr-2">Modifica</button></Link>
                                <button className="btn btn-danger" onClick={() => { if (window.confirm('Sei sicuro di voler eliminare questo impianto?')) onDelete(plant.id) }}>Elimina</button></td>
                        </tr>))}
                </tbody>
            </table>
            <Sponsors schoolId={props.match.params.id}></Sponsors>
        </div>
    );
}

export default withFirebase(School);
