import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import Liters from '../Liters';
import LoaderText from '../LoaderText';
import { withAuthorization, withEmailVerification } from '../Session';
import withPhoneVerification from '../Session/withPhoneVerification';



class PlantPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            plant: null,
            school: null,
            city: null
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.firebase.plant(this.props.match.params.id).get().then((document) => {
            var data = document.data()
            this.props.firebase.fileUrl(data.image).then((image) => {
                this.setState({
                    plant: {
                        name: data.name,
                        image: image,
                        liters: data.liters
                    }
                })
            })
            this.props.firebase.school(data.school_id).get().then((document) => {
                var data = document.data()
                this.setState({
                    school: {
                        id: document.id,
                        name: data.name,
                        liters: data.liters
                    }
                })
                this.props.firebase.city(data.city_id).get().then((document) => {
                    var data = document.data()
                    this.setState({
                        city: {
                            id: document.id,
                            name: data.name
                        }
                    })
                })
            })
        })

        this.props.firebase.files().where("plant_id", "==", this.props.match.params.id).get().then((query) => {
            var files = query.docs.map((doc) => {
                var data = doc.data()
                return {
                    id: doc.id,
                    name: data.name,
                    real: data.real
                }
            });
            this.setState({ files: files })
        })
    }

    openFile = (file) => {
        var ref = this.props.firebase.storageRef(file.name);
        ref.getDownloadURL().then(function (url) {
            window.open(url, "_blank")
        })
    }

    render() {
        var image
        if (this.state.plant) {
            image = this.state.plant.image || process.env.PUBLIC_URL + '/placeholder.jpg'
        }
        return this.state.plant ? <div>
            <div className="mt-3">
                <Link to={ROUTES.HOME}>
                    Tutti i comuni
                </Link> /
            {
                    this.state.city && this.state.school ?
                        <span>
                            <span> <Link to={"/app/comuni/" + this.state.city.id} >
                                {this.state.city.name}
                            </Link> / </span>
                            <span> <Link to={"/app/scuole/" + this.state.school.id} >
                                {this.state.school.name}
                            </Link> / </span>
                        </span> : ''
                }
            </div>
            <div className="row">
                <div className="col-12 col-md-8 mt-3">
                    <h2>{this.state.plant.name}</h2>
                    <br />
                    <h3>Tabella dati</h3>
                    <Liters name={this.state.plant.name} liters={this.state.plant.liters} />
                </div>
                <div className="col-12 col-md-4 mt-3">
                    <h2>Allegati dell'impianto</h2>
                    {this.state.files.length > 0 ?
                        <ul>
                            {
                                this.state.files.map(
                                    (file) =>
                                        <li key={file.id}>
                                            <div className="btn btn-link text-left" onClick={() => this.openFile(file)}>{file.real}</div>
                                        </li>
                                )
                            }</ul> : <p>Nessun file</p>}
                    <img src={image} width="100%" alt="Immagine impianto" className="round-image" />
                </div>
            </div>
        </div> : <LoaderText text="Caricando impianto" />
    }
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withPhoneVerification,
    withAuthorization(condition),
)(PlantPage);
