import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import { useFirebase } from '../../Firebase/context';
import Loader from '../Loader';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Users = (props) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const firebase = useFirebase()
    const columns = [
        {
            title: "Id",
            field: "id",
            hidden: true
        },
        {
            title: "Nome cognome",
            field: "name",
        },
        {
            title: "Email",
            field: "email",
        },
        {
            title: "Numero di telefono",
            field: "phone",
        },
        {
            title: "Comune",
            field: "city",
        },
        {
            title: "Scuola",
            field: "school",
        },
        {
            title: "Da eliminare",
            field: "requestDelete",
            type: 'boolean'
        },
        {
            title: "Ultimo login",
            field: "lastLogin",
            type: 'datetime'
        },
    ]

    const fetchUsers = useCallback(
        async () => {
            var data = [];
            var result = await firebase.users().orderBy("surname", "asc").get()
            for (var doc of result.docs) {
                var docData = doc.data()
                if (doc.exists) {
                    var cityData = null, schoolData = null
                    var city = await firebase.city(docData.cityId).get()
                    if (city.exists) {
                        cityData = city.data()
                        var school = await firebase.school(docData.schoolId).get()
                        if (school.exists)
                            schoolData = school.data()
                    }
                    data.push({
                        'id': doc.id,
                        'name': docData.name + " " + docData.surname,
                        'email': docData.email,
                        'phone': docData.phone,
                        'city': cityData ? cityData.name : '',
                        'school': schoolData ? schoolData.name : '',
                        'requestDelete': docData.requestDelete != null && docData.requestDelete ? true : false,
                        'lastLogin': docData.lastLogin != null ? docData.lastLogin.toDate() : null
                    })
                }
            }
            setData(data)
            setLoading(false)
        },
        [firebase],
    );

    useEffect(() => {
        console.log("users loaded")
        fetchUsers()
    }, [fetchUsers]);

    function deleteUser(userId) {
        firebase.user(userId).delete().then(() => {
            fetchUsers()
        })
    }

    return (!loading ? (
        <div className="container mt-3">
            <MaterialTable
                title={"Utenti"}
                icons={tableIcons}
                columns={columns}
                data={data}
                actions={[
                    {
                        icon: DeleteOutline,
                        tooltip: 'Delete User',
                        onClick: (event, rowData) => {
                            if (window.confirm('Sei sicuro di voler eliminare questo utente?')) deleteUser(rowData.id)
                        }
                    }
                ]}
                options={{
                    columnResizable: true,
                    filtering: true,
                    exportButton: true
                }}
            />
        </div>) : (<Loader />))
}

export default Users;