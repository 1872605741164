import React, { Component } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withFirebase } from "../../../Firebase";
import Template from '../template_signup'

class PhoneVerificationPageBase extends Component {
    constructor(props) {
        super(props);
        this.state = { isSent: false, phoneNumber: '+39', confirmCode: '', captchaVerified: false };
    }

    onSendPhoneVerification = () => {
        this.props.firebase.updateUserPhone(this.state.phoneNumber)
        this.props.firebase
            .doSendPhoneVerification(this.state.phoneNumber, window.recaptchaVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                this.setState({ isSent: true })
            }).catch((error) => {
                console.error(error);
                toast.error("Qualcosa è andato storto, riprova", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            });
    };
    confirmCode = () => {
        this.props.firebase.confirmPhoneCode(window.confirmationResult, this.state.confirmCode, (error) => {
            console.log(error);
            var errorMsg = "Qualcosa è andato storto, riprova"
            if (error['code'] === 'auth/credential-already-in-use') {
                errorMsg = "Numero di telefono già utilizzato"
            }
            toast.error(errorMsg, {
                position: toast.POSITION.BOTTOM_CENTER
            })
        }).then((result) => {
            console.log("success")
        });
    }
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === "phoneNumber") {
            var num = event.target.value
            var suf = num.substring(0, 3)
            if (suf !== "+39") {
                this.setState({ phoneNumber: '+39' })
            }
        }
    };

    componentDidMount() {
        window.recaptchaVerifier = this.props.firebase.getRecaptcha("recaptcha-container", (response) => {
            console.log("captcha success")
            this.setState({ captchaVerified: true })
        }, () => {
            console.log("Captcha expired")
            this.setState({ captchaVerified: false })
        })
        window.recaptchaVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
        });
    }

    signOut = () => {
        this.props.firebase.doSignOut();
    }

    render() {
        var isInvalid = !this.state.captchaVerified
        return (
            <Template title="Conferma telefono">
                {!this.state.isSent ?
                    <div>
                        <div className="form-label-group">
                            <input type="text" id="inputPhone" className="form-control" placeholder="Numero di telefono"
                                name="phoneNumber" value={this.state.phoneNumber} onChange={this.onChange} disabled={this.state.isSent} />
                            <label htmlFor="inputPhone">Numero di telefono</label>
                        </div>
                        <div id="recaptcha-container" />
                    </div>
                    : <div className="form-label-group">
                        <input type="text" id="inputCode" className="form-control" placeholder="Codice di conferma"
                            name="confirmCode" value={this.state.confirmCode} onChange={this.onChange} />
                        <label htmlFor="inputCode">Codice di conferma</label>
                    </div>}

                {!this.state.isSent ?
                    <button onClick={this.onSendPhoneVerification} disabled={isInvalid}
                        className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2 mt-3">Invia SMS</button>
                    : <button onClick={this.confirmCode}
                        className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2 mt-3">Conferma</button>}
                <br />
                <div className="text-center">
                    oppure <br />
                    <button onClick={this.signOut} className="btn btn-link ">Accedi con un altro account</button>
                </div>
            </Template>
        );
    }
}

const PhoneVerificationPage = withFirebase(PhoneVerificationPageBase);

export default PhoneVerificationPage;
