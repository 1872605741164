import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

import TemplateSignup from '../Authentication/SignUp/template_signup'

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified;

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    signOut = () => {
      this.props.firebase.doSignOut();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <TemplateSignup title="Verifica email">
                {this.state.isSent ? (
                  <p>
                    Email di verifica inviata a <strong>{authUser.email}</strong>
                  </p>
                ) : (
                    <p>
                      Verifica la tua E-Mail: Controlla la tua casella di posta elettronica
                      (anche la cartella spam) per la mail di conferma
                      oppure invia un'altra e-mail di conferma.
                    </p>
                  )}
                <div className="text-center">
                  <button
                    type="button"
                    onClick={this.onSendEmailVerification}
                    disabled={this.state.isSent}
                    className="btn btn-primary">
                    Invia di nuovo email
                </button></div>
                <br />
                <div className="text-center">
                  oppure <br />
                  <button onClick={this.signOut} className="btn btn-link ">Accedi con un altro account</button>
                </div>
              </TemplateSignup>
            ) : (
                <Component {...this.props} />
              )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
