import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withFirebase } from '../../Firebase';
import Uploader from '../Uploader';

const Plant = (props) => {
    const [loading, setLoading] = useState(true)
    const [plantName, setPlantName] = useState('')
    const [plantLiters, setPlantLiters] = useState(0)
    const [plantSchoolId, setPlantSchoolId] = useState('')
    const [plantImage, setPlantImage] = useState('')
    const [updatePlantEnabled, setUpdatePlantEnabled] = useState(true)
    const [files, setFiles] = useState([])

    useEffect(() => {
        setLoading(true)
        props.firebase
            .plant(props.match.params.id).get().then((result) => {
                var data = result.data()
                setLoading(false)
                setPlantName(data.name)
                setPlantLiters(data.liters)
                setPlantSchoolId(data.school_id)
                setPlantImage(data.image)
            })
        var unsubscribeFiles = props.firebase.files().where("plant_id", "==", props.match.params.id).onSnapshot((result) => {
            var filesRes = result.docs.map((file) => {
                var data = file.data();
                return {
                    id: file.id,
                    name: data.name,
                    real: data.real
                }
            })
            setFiles(filesRes)
        })
        return () => {
            unsubscribeFiles()
        }
    }, [props])

    function updatePlantData() {
        setUpdatePlantEnabled(false)
        props.firebase.updatePlant(props.match.params.id, plantName, plantLiters).then(() => {
            setUpdatePlantEnabled(true)
            toast.success("Dati aggiornati con successo", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    function openFile(fileName) {
        var ref = props.firebase.storageRef(fileName);
        ref.getDownloadURL().then(function (url) {
            window.open(url, "_blank")
        })
    }

    async function onImageUploaded(name, realName) {
        await props.firebase.plant(props.match.params.id).update({
            'image': name
        })
        setPlantImage(name)
    }

    async function removePlantImage() {
        await props.firebase.plant(props.match.params.id).update({
            'image': ''
        })
        await props.firebase.deleteFile(plantImage)
        setPlantImage(null)
    }

    function onDeleteFile(file) {
        props.firebase.deletePlantFile(file.id)
    }

    function onFileUploaded(name, realName) {
        props.firebase.files().add({
            name: name,
            real: realName,
            plant_id: props.match.params.id
        })
    }

    return loading ? <Loader /> : (
        <div className="container">
            <div className="mt-3">
                <Link to={"/admin/scuole/" + plantSchoolId}> <button className="btn btn-link">Indietro</button></Link>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <h3>Impianto</h3>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-default">Nome impianto</span>
                        </div>
                        <input type="text" className="form-control" value={plantName} onChange={(e) => setPlantName(e.target.value)}
                            aria-label="Small" aria-describedby="inputGroup-sizing-default" name="plantName" />
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-default">Litri d'acqua</span>
                        </div>
                        <input type="text" className="form-control" value={plantLiters} onChange={(e) => setPlantLiters(e.target.value)}
                            aria-label="Small" aria-describedby="inputGroup-sizing-default" name="plantLiters" />
                    </div>
                    <button onClick={updatePlantData} className="btn btn-primary" disabled={!updatePlantEnabled}>Salva</button>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <h5>Immagine</h5>
                    {
                        plantImage !== null && plantImage !== '' ?
                            <div>Immagine caricata <button className="btn btn-primary mr-2" onClick={() => openFile(plantImage)}>Visualizza</button> <button className="btn btn-danger" onClick={removePlantImage}>Cancella</button></div>
                            :
                            <Uploader onFileUploaded={onImageUploaded} />
                    }
                </div>
            </div>
            <br />
            <h3>Gestione file</h3>
            <Uploader onFileUploaded={onFileUploaded} />
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">File</th>
                        <th scope="col" style={{ width: '250px' }}>Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map((file) => (
                        <tr key={file.id}>
                            <td>{file.real}</td>
                            <td><button className="btn btn-primary mr-2" onClick={() => openFile(file.name)}>Visualizza</button>
                                <button className="btn btn-danger" onClick={() => { if (window.confirm('Sei sicuro di voler eliminare questo file?')) onDeleteFile(file) }}>Elimina</button></td>
                        </tr>))}
                </tbody>
            </table>
        </div>
    );
}
export default withFirebase(Plant);
