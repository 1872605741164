import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

class Sponsors extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sponsors: null
        }
    }
    async componentDidMount() {
        this.props.firebase.sponsors(this.props.cityId, this.props.schoolId, this.props.plantId).get().then(async (res) => {
            var sponsors = []
            for (var doc of res.docs) {
                var data = doc.data()
                var image = ""
                if (data.image && data.image !== '')
                    image = await this.props.firebase.fileUrl(data.image)
                sponsors.push({
                    id: doc.id,
                    name: data.name,
                    link: data.link,
                    image: image,
                })
            }
            this.setState({
                sponsors: sponsors
            })
        });
    }
    render() {
        return this.state.sponsors && this.state.sponsors.length > 0 ? <div>
            <h3>I nostri sponsor</h3>
            <div className={this.props.column ? 'col' : 'row'}>
                {this.state.sponsors.map((sponsor) => {
                    var image = sponsor.image || process.env.PUBLIC_URL + '/placeholder.jpg'
                    return (
                        <div key={sponsor.id} className={this.props.column ? '' : "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 p-2"}>
                            <a href={sponsor.link} target="_blank" className="hvr-shrink nounderline" rel="noopener noreferrer">
                                    <div class="sponsor">
                                        <img src={image} class="img img-responsive full-width" />
                                    </div>
                            </a>
                        </div>
                    )
                })}
            </div>
        </div> : <div></div>
    }
}

export default withFirebase(Sponsors);
