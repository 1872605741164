import React, { useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import withPhoneVerification from '../Session/withPhoneVerification';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';

import HomePage from '../Home'
import SchoolPage from '../School'
import AccountPage from '../Account'
import CityPage from '../City'
import PlantPage from '../Plant'

const Navigation = (props) => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    return (
        <div className="container-flex">
            <nav className="navbar navbar-expand-lg navbar-dark bg-gradient-custom">
                <div className="container">
                    <Link to={ROUTES.HOME}><span className="navbar-brand mb-0 h1">Scuoliamola</span></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavbar" aria-controls="mainNavbar" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="mainNavbar">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to={ROUTES.ACCOUNT}><div className="btn text-white">Account</div></Link>
                            </li>
                            <li className="nav-item">
                                <div className="btn text-white" onClick={props.firebase.doSignOut}>Esci</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container" style={{ minHeight: '100vh' }}>
                <Switch>
                    <Route exact path={ROUTES.HOME} component={HomePage} />
                    <Route exact path={ROUTES.SCHOOL} component={SchoolPage} />
                    <Route exact path={ROUTES.CITY} component={CityPage} />
                    <Route exact path={ROUTES.PLANT} component={PlantPage} />
                    <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
                </Switch>
            </div>

            <footer className="py-5 bg-dark">
                <div className="container">
                    <p className="m-0 text-center text-white">PIER H20 Srl - Via Bisalta, 70 - 12100 Cuneo (CN) - R.I./C.F./P.IVA: 03318150046</p>
                    <p className="m-0 text-center text-secondary">Copyright &copy; 2020</p>
                </div>
            </footer>
        </div>
    )
};

const condition = authUser => !!authUser;
export default compose(
    withEmailVerification,
    withPhoneVerification,
    withFirebase,
    withAuthorization(condition),
)(Navigation);
