export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_UP_PHONE = '/signup/phone';
export const SIGN_IN = '/signin';
export const APP = '/app';
export const HOME = '/app/home';
export const CITY = '/app/comuni/:id';
export const SCHOOL = '/app/scuole/:id';
export const PLANT = '/app/impianti/:id';
export const ACCOUNT = '/app/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_CITY_DETAIL = '/admin/comuni/:id';
export const ADMIN_SCHOOL_DETAIL = '/admin/scuole/:id';
export const ADMIN_PLANT_DETAIL = '/admin/impianti/:id';
export const ADMIN_USERS = '/admin/utenti';
export const ADMIN_EDIT = '/admin/edit';
export const ADMIN_SPONSOR = '/admin/sponsor/:id';
