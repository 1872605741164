import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../Firebase/context';
import Loader from '../Loader';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Edit = () => {
    const [loading, setLoading] = useState(true)
    const [plants, setPlants] = useState([])
    const firebase = useFirebase()

    useEffect(() => {
        var unsubscribe = firebase.plants().orderBy("name", "asc").onSnapshot((result) => {
            var plantsRes = []
            result.docs.forEach((plant) => {
                plantsRes.push({
                    id: plant.id,
                    name: plant.data().name,
                    liters: plant.data().liters
                })
            });
            setLoading(false)
            setPlants(plantsRes)
        })
        return () => {
            unsubscribe()
        }
    }, [firebase]);

    function updateLiters(plantId) {
        console.log("update liters " + plantId)
        var liters = document.getElementById("liters" + plantId).value
        setLoading(true)
        firebase.updatePlant(plantId, null, liters)
    }

    return (!loading ? (
        <div className="container">
            <h3 className="mt-3">Modifica litri impianti</h3>
            <div className="row">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Impianto</th>
                            <th scope="col">Litri</th>
                            <th scope="col" style={{ width: '200px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {plants.map((plant) => {
                            return (
                                <tr key={plant.id}>
                                    <td><Link to={"/admin/impianti/" + plant.id}>{plant.name}</Link></td>
                                    <td>{plant.liters}</td>
                                    <td>
                                        <form>
                                            <div className="input-group">
                                                <input type="text" id={"liters" + plant.id} className="form-control" />
                                                <button className="btn btn-primary" onClick={() => { updateLiters(plant.id) }}>Aggiorna</button>
                                            </div>
                                        </form>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>) : (<Loader />))
}

export default Edit;